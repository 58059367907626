import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form, Row } from 'react-bootstrap';
import { useRootStore } from '../../root/RootStoreHooks';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';
import styles from './member-agreement-order-review.module.scss'
export const MemberAgreementOrderReview = observer(() => {
  const { memberAgreementOrderReview, orderReviewAgreementEnabled } = useRootStore().moduleStores;
  const store = memberAgreementOrderReview;
  const { t } = useTranslation();
  return (
    <Row>
      { orderReviewAgreementEnabled && (
        <Form noValidate className={styles.memberAgreementClassChange}>
          <div className={styles.memberAgreementBGColor}>

            <YlCheckBox
              size={12}
              field={store!.reactForm.$.agreementdirectMarket}
              hideError={true}
              label={
                <p>
                  {t('ConsentAgreementCheckboxText')}
                  <span>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                      href={t('ConsentAgreementLinkUrl')}
                    >
                      {t('directMarketingConsentAgreement')}
                    </a>
                    .
                  </span>
                </p>
              }
              required
              id="directMarketingConsentAgreement"
            />

            <YlCheckBox
              size={12}
              field={store!.reactForm.$.termsAndConditionsAgreement}
              hideError={true}
              label={
                <p>
                  {t('TermsAndConditionsCheckboxText')}
                  <span>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                      href={t('TermsAndConditionsLinkUrl')}
                    >
                      {t('TermsAndConditionsLinkText')}
                    </a>
                    .
                  </span>
                </p>
              }
              id="TermsAndConditionsCheckbox"
              required
            />
            <YlCheckBox
              size={12}
              hideError={true}
              field={store!.reactForm.$.confirmAccuracyCheckbox}
              label={t('ConfirmAccuracyCheckboxText')}
              id="ConfirmAccuracyCheckbox"
              required
            />
          </div>
          <br />
        </Form>
      )}
    </Row>
  );
});
