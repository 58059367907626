import React from 'react';
import { observer } from 'mobx-react-lite';
import { PopularKitTab } from './tabbed-layout/PopularKitTab';
import { BasicKitTab } from './tabbed-layout/BasicKitTab';
import { PremiumKitTab } from './tabbed-layout/PremiumKitTab';
import { SpecialtyKitTab } from './tabbed-layout/SpecialtyKitTab';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';

export const StarterKitTabContent = observer(function StarterKitTabContent() {
  const store = useModuleStore('enrollmentKitSelection');

  switch (store.currentTab.tabType) {
    case 'Popular':
      return <PopularKitTab tab={store.currentTab} />;
    case 'Premium':
      return <PremiumKitTab tab={store.currentTab} />;
    case 'Specialty':
      return <SpecialtyKitTab tab={store.currentTab} />;
    case 'Basic':
      return <BasicKitTab tab={store.currentTab} />;
    default:
      throw new Error('no tab for type');
  }
});
