import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { OrderSummaryItemList } from './OrderSummaryItemList';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
 
export const OrderSummary = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const orderSummaryStore = useModuleStore('orderSummary');

  return useObserver(() => (
    <>
      <h2 className="text-primary">{t('OrderSummary')}</h2>
      <OrderSummaryItemList label="EnrollmentKit" items={orderSummaryStore.enrollmentItems} />
      {!orderSummaryStore.usePremiumStarterKitAsErOrder && (
        <OrderSummaryItemList label="EssentialRewardsKit" items={orderSummaryStore.essentialRewardItems} />
      )}
      {!orderSummaryStore.hideEditStepButton && (
        <Button onClick={() => rootStore.stepsManager.goToStep(orderSummaryStore.editStepNumber)} variant="link">
          {t('EditKitSelection')}
        </Button>
      )}
    </>
  ));
};
