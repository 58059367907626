import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { EnrollmentRootStore } from '../../root/EnrollmentRootStore';
import { Redirector } from '../../../infrastructure/redirecting/Redirector';

export async function saveStepAndGoToCustomize(rootStore: EnrollmentRootStore) {
  const enrollmentStep = rootStore.stepsManager.currentStep;
  const saveSucceeded = await enrollmentStep.saveModuleStates();
  if (
    !rootStore.moduleStores.socialLinkCreatorEntry &&
    !saveSucceeded &&
    !rootStore.moduleStores.meoKitSelection &&
    !rootStore.moduleStores.socialLinkEntry
  ) {
    return;
  }

  try {
    rootStore.spin();
    const response = await enrollmentResource.get('/order-customization-redirect-url');
    Redirector.redirect(response.data.redirectUrl);
  } finally {
    rootStore.unspin();
  }
}
